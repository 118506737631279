import { PeriodParamsWithOptionalCountback } from '../../../../public/datafeeds/udf/src/history-provider'
import { IBasicDataFeed } from '../../../charting_library'
import {
  Bar,
  DatafeedConfiguration,
  ErrorCallback,
  HistoryCallback,
  HistoryMetadata,
  LibrarySymbolInfo,
  OnReadyCallback,
  ResolutionString,
  ResolveCallback,
  SearchSymbolsCallback,
  SeriesFormat,
  SubscribeBarsCallback,
  Timezone,
} from '../../../charting_library/datafeed-api'
import { environment } from '../../../config/settings'
import { legacyApiFetch } from '../../../helpers/ProxyApiFetch'
import { State } from '../../../reducers'
import apiClient from '../../../services/ApiClient'
import { store } from '../../../services/reduxService'
import { wsTVChartService } from '../../../services/wsTVChartService'
import { log } from './helper'
import { HistoryFullDataResponse } from './type'

const supportedResolutions: ResolutionString[] = [
  '5',
  '10',
  '30',
  '60',
  '240',
  'D',
] as ResolutionString[]

let _lastCandleTimestamp = 0

const config: DatafeedConfiguration = {
  supported_resolutions: supportedResolutions,
}
const getPriceScale = (price: number): number => {
  const pricePower = price === 0 ? 0 : Math.floor(Math.log10(Math.abs(price))) // 10000 => 4 | 0.00001 => -5
  const priceScale = Math.pow(10, 5 - pricePower)
  return priceScale
}
const datafeed = {
  chartId: '',
  subscriberId: '',
  lastBar: {},
  searchSymbols: (
    userInput: string,
    exchange: string,
    symbolType: string,
    onResult: SearchSymbolsCallback
  ): void => {
    // used for "Compare" feature
    log('[tvdf: search symbols]')
    apiClient.tv
      .searchSymbols({ userInput, exchange, symbolType })
      .then((response) => {
        const symbols = response.responseData
        onResult(symbols || [])
      })
      .catch((e: Error) => {
        console.error(e)
        onResult([])
      })
  },
  onReady: function onReady(cb: OnReadyCallback): void {
    log('[tvdf: onReady]: executed')
    setTimeout(() => cb(config), 0) // resolves warning: `onReady` should return result asynchronously. Use `setTimeout` with 0 interval to execute the callback function.
  },
  resolveSymbol: (symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback): void => {
    log('[tvdf: resolveSymbol]: executed', symbolName)
    // looking for token data in redux. if note - then fallback to symbols API call
    const tokens = (store.getState() as State).tokens.tokens
    const [id, currency] = symbolName.split('_')
    const token = tokens.find((x) => x.id === id)
    if (token) {
      const priceScale = getPriceScale(currency === 'USD' ? token.priceUSD : token.priceETH)
      const symbolInfo = {
        id: symbolName,
        name: `${token.name}_${currency.toUpperCase()}`,
        description: `${token.name}/${token.symbols}`,
        exchange: `${token.network.toUpperCase()}_DEXGURU`,
        ticker: symbolName,
        full_name: symbolName,
        listed_exchange: 'dex.guru',
        type: 'crypto',
        session: '24x7',
        data_status: 'pulsed' as 'streaming' | 'endofday' | 'pulsed' | 'delayed_streaming',
        has_daily: true,
        has_weekly_and_monthly: true,
        has_empty_bars: true,
        force_session_rebuild: true,
        has_no_volume: false,
        volume_precision: 2,
        timezone: 'Etc/UTC' as Timezone,
        format: 'price' as SeriesFormat,
        pricescale: priceScale,
        minmov: 1,
        has_intraday: true,
        supported_resolutions: [
          '1',
          '5',
          '10',
          '30',
          '60',
          '240',
          '720',
          '1D',
          '1W',
        ] as ResolutionString[],
      }

      setTimeout(() => onResolve(symbolInfo), 0) // resolves warning: `resolveSymbol` should return result asynchronously. Use `setTimeout` with 0 interval to execute the callback function.
    } else {
      legacyApiFetch<LibrarySymbolInfo>(
        environment.getDexGuruAPIV1Url(),
        `/tradingview/symbols?symbol=${symbolName}`,
        {
          onError: () => {
            throw new Error(`Unable to fetch symbol data for ${symbolName}`)
          },
        }
      )
        .then((symbol) => {
          if (!symbol) {
            return
          }

          setTimeout(() => onResolve(symbol), 0) // resolves warning: `resolveSymbol` should return result asynchronously. Use `setTimeout` with 0 interval to execute the callback function.
        })
        .catch((error) => onError(error))
    }
  },
  getBars: async function getBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParamsWithOptionalCountback,
    onHistoryCallback: HistoryCallback,
    onErrorCallback: ErrorCallback
  ): Promise<void> {
    const { from, to } = periodParams
    log('[tvdf: getBars]: Method called', symbolInfo, resolution, from, to)

    try {
      const response = await legacyApiFetch<HistoryFullDataResponse>(
        environment.getDexGuruAPIV1Url(),
        `/tradingview/history?symbol=${symbolInfo.ticker}&resolution=${resolution}&from=${from}&to=${to}`,
        {
          onError: () => {
            throw new Error(`Unable to fetch history data for ${symbolInfo.ticker}`)
          },
        }
      )

      const bars: Bar[] = []
      const meta: HistoryMetadata = {
        noData: false,
      }

      if (!response || response.s !== 'ok') {
        meta.noData = true
        meta.nextTime = response?.nextTime
      } else {
        // save last candle to cache
        _lastCandleTimestamp = response.t[response.t.length - 1]

        const volumePresent = response.v !== undefined
        const ohlPresent = response.o !== undefined

        for (let i = 0; i < response.t.length; ++i) {
          const barValue: Bar = {
            time: response.t[i] * 1000,
            close: parseFloat(response.c[i]),
            open: parseFloat(response.c[i]),
            high: parseFloat(response.c[i]),
            low: parseFloat(response.c[i]),
          }

          if (ohlPresent) {
            barValue.open = parseFloat(response.o[i])
            barValue.high = parseFloat(response.h[i])
            barValue.low = parseFloat(response.l[i])
          }

          if (volumePresent) {
            barValue.volume = parseFloat(response.v[i])
          }

          bars.push(barValue)
        }
      }

      this.lastBar = bars[bars.length - 1]
      onHistoryCallback(bars, meta)
    } catch (error) {
      console.error('[tvdf: getBars]: Get error', error)
      onErrorCallback('Failed to update bar')
    }
  },
  subscribeBars: function subscribeBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onRealtimeCallback: SubscribeBarsCallback,
    subscriberId: string
  ): void {
    this.subscriberId = subscriberId
    wsTVChartService.subscribeBars(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberId,
      this.chartId,
      _lastCandleTimestamp
    )
  },
  unsubscribeBars: function unsubscribeBars(subscriberId: string): void {
    wsTVChartService.unsubscribeBars(subscriberId, this.chartId)
  },
}

const DataFeed = (id: string): IBasicDataFeed => {
  datafeed.subscriberId = ''
  datafeed.chartId = id
  datafeed.subscribeBars = datafeed.subscribeBars.bind(datafeed)
  datafeed.unsubscribeBars = datafeed.unsubscribeBars.bind(datafeed)
  return datafeed
}

export default DataFeed
